export const getParamFromSearchUrl = param => {
  const searchUrl = new URLSearchParams(window.location.search);
  return searchUrl.get(param);
};

export const getPageUrlForIframe = () => {
  const url = new URL(document.referrer);
  return `${url.hostname}`;
};

export const getSignatureUrl = url => {
  const urlObject = new URL(url);
  const params = new URLSearchParams(urlObject.search);

  params.delete('ref');
  params.delete('layout');

  const queryString = params.toString();

  return  urlObject.origin + urlObject.pathname + ( queryString.length ? `?${queryString}` : '' );
};
