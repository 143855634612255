import { getParams } from '@/utils/getParams';
import { getSignatureUrl } from '@/utils/urls';

function getRequest(id: string) {
  const base = import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL;
  const url = `${ base }/v1/request/${ id }`;

  return fetch(url).then(async res => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error(`Request failed with status ${res.status}`);
    }
  });
}

function getProductSettings(id: string, payload: Record<string, unknown>) {
  let originalUrl = null;
  try {
    originalUrl = getSignatureUrl(document.URL);
  } catch (e) {
    throw Error('Failed to get signature URL', { cause: e });
  }

  const params = {
    ...payload,
    originalUrl,
  };

  const base = import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL;

  const filteredParamsArray = Object.entries(params).filter(a => a[1]);
  const stringParams = new URLSearchParams(filteredParamsArray);
  const url = `${ base }/v2/product/${ id }/settings?${ stringParams }`;

  return fetch(url).then(res => res.json());
}

const params = getParams();

export function loadRequest() {
  if (params.businessAccountProductUuid && !params.requestId) {
    return getProductSettings(params.businessAccountProductUuid, {
      transactionFlow: params.transactionFlow,
      partnerUserId: params.partnerUserId,
      successReturnURL: params.successReturnURL,
      failureReturnURL: params.failureReturnURL,
      signature: params.signature,
    });
  }

  return getRequest(params.requestId);
}
