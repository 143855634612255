import { Flow } from '@/const.js';

const CRYPTO_ADDRESS = 'accountAddress';
const CRYPTO_CURRENCY = 'cryptoCurrency';
const FIAT_CURRENCY = 'fiatCurrency';
const FIAT_AMOUNT = 'fiatAmount';
const CRYPTO_AMOUNT = 'cryptoAmount';
const MODE = 'mode';
const PAYMENT_METHOD = 'paymentMethod';
const BANK_RESIDENCY = 'bankResidency';
export const BUY = 'buy';
export const SELL = 'sell';

const checkForValue = value => value !== null && value !== '' && value !== undefined;

export const handleUrlParams = urlParams => {
  const cryptoAddress = urlParams.get(CRYPTO_ADDRESS);
  const transactionFlow = urlParams.get(MODE);
  const cryptoCurrency = urlParams.get(CRYPTO_CURRENCY);
  const fiatCurrency = urlParams.get(FIAT_CURRENCY);
  const fiatAmount = urlParams.get(FIAT_AMOUNT);
  const cryptoAmount = urlParams.get(CRYPTO_AMOUNT);
  const paymentMethod = urlParams.get(PAYMENT_METHOD);
  const bankResidency = urlParams.get(BANK_RESIDENCY);

  const isSellMode = transactionFlow === SELL;

  return {
    cryptoAddress,
    currencyCodeTo: isSellMode ? fiatCurrency : cryptoCurrency,
    currencyCodeFrom: isSellMode ? cryptoCurrency : fiatCurrency,
    amountFrom: isSellMode ? cryptoAmount : fiatAmount,
    amountTo: isSellMode ? fiatAmount : cryptoAmount,
    transactionFlow: transactionFlow && isSellMode ? Flow.SELL_FLOW : Flow.BUY_FLOW,
    paymentMethod,
    bankResidency,
  };
};

export const supports = urlParams => {
  const cryptoAddress = urlParams.get(CRYPTO_ADDRESS);
  const cryptoCurrency = urlParams.get(CRYPTO_CURRENCY);
  const fiatCurrency = urlParams.get(FIAT_CURRENCY);

  return [cryptoAddress, cryptoCurrency, fiatCurrency].every(checkForValue);
};

export const has = params => {
  const cryptoAddress = params[CRYPTO_ADDRESS];
  const cryptoCurrency = params[CRYPTO_CURRENCY];
  const fiatCurrency = params[FIAT_CURRENCY];

  return [cryptoAddress, cryptoCurrency, fiatCurrency].every(checkForValue);
};
