import { Flow } from '@/const.js';

const CRYPTO_ADDRESS = 'cryptoAddress';
const CURRENCY_CODE_TO = 'currencyCodeTo';
const CURRENCY_CODE_FROM = 'currencyCodeFrom';
const AMOUNT_FROM = 'amountFrom';
const AMOUNT_TO = 'amountTo';
const TRANSACTION_FLOW = 'transactionFlow';
const PAYMENT_METHOD = 'paymentMethod';

const checkForValue = value => value !== null && value !== '' && value !== undefined;

export const handleUrlParams = urlParams => {
  const cryptoAddress = urlParams.get(CRYPTO_ADDRESS);
  const currencyCodeTo = urlParams.get(CURRENCY_CODE_TO);
  const currencyCodeFrom = urlParams.get(CURRENCY_CODE_FROM);
  const amountFrom = urlParams.get(AMOUNT_FROM);
  const amountTo = urlParams.get(AMOUNT_TO);
  const transactionFlow = urlParams.get(TRANSACTION_FLOW);
  const paymentMethod = urlParams.get(PAYMENT_METHOD);

  return {
    cryptoAddress,
    currencyCodeTo,
    currencyCodeFrom,
    amountFrom,
    amountTo,
    transactionFlow,
    paymentMethod,
  };
};

export const supports = urlParams => {
  const currencyCodeTo = urlParams.get(CURRENCY_CODE_TO);
  const currencyCodeFrom = urlParams.get(CURRENCY_CODE_FROM);
  const transactionFlow = urlParams[TRANSACTION_FLOW] ?? Flow.BUY_FLOW;

  if (!transactionFlow || transactionFlow === Flow.BUY_FLOW) {
    return [currencyCodeTo].every(checkForValue);
  }

  if (transactionFlow === Flow.SELL_FLOW) {
    return [currencyCodeFrom].every(checkForValue);
  }

  return [currencyCodeTo, currencyCodeFrom].every(checkForValue);
};

export const has = params => {
  const currencyCodeTo = params[CURRENCY_CODE_TO];
  const currencyCodeFrom = params[CURRENCY_CODE_FROM];
  const transactionFlow = params[TRANSACTION_FLOW] ?? Flow.BUY_FLOW;

  if (!transactionFlow || transactionFlow === Flow.BUY_FLOW) {
    return [currencyCodeTo].every(checkForValue);
  }

  if (transactionFlow === Flow.SELL_FLOW) {
    return [currencyCodeFrom].every(checkForValue);
  }

  return [currencyCodeTo, currencyCodeFrom].every(checkForValue);
};
