export const Locale = {
  EN: 'en', DE: 'de', RU: 'ru', IT: 'it', FR: 'fr', ES: 'es', PT: 'pt', KO: 'ko',
};

export const defaultLocale = Locale.EN;

export const Layout = {
  DEFAULT: 'default', LIGHT: 'light', EMBED: 'embed',
};

export const IndexPage = {
  APPLE_PAY: 'applePay',
};

export const Direction = {
  FROM: 'from', TO: 'to',
};

export const Flow = {
  BUY_FLOW: 'buyCrypto', SELL_FLOW: 'sellCrypto', SWAP_FLOW: 'swapCrypto',
};
